<template>
	<div>
		<!-- 	<navbar></navbar> -->

		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide">

					<div class="bg bg3">

						<div class="text">
							<div class="mask"></div>
							<h4>违规监测 预警处置</h4>
						</div>
					</div>

				</div>
				<div class="swiper-slide">
					<div class="bg bg2">

						<div class="text">
							<div class="mask"></div>
							<h4>能耗分析服务 双碳经济</h4>
						</div>

					</div>
				</div>
				<div class="swiper-slide">
					<div class="bg bg1">

						<div class="text">
							<div class="mask"></div>
							<h4>智慧用电 数据中心</h4>
						</div>
					</div>
				</div>
			</div>
			<!-- 如果需要分页器 -->
			<div class="swiper-pagination"></div>

			<!-- 如果需要导航按钮 -->
			<!--    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->

			<!-- 如果需要滚动条 -->
			<!-- <div class="swiper-scrollbar"></div> -->
		</div>


		<!-- 应用场景 -->
		<div class="core-main">
			<h1>应用场景</h1>
			<h4>SCENARIOS</h4>
			<div class="scenarios-main">
				<img src="../assets/img/4.png" alt="智慧用电" width="500">

				<div class="scenarios-item">
					<div style="background-color: #1989fb;">
						<img src="../assets/img/d2.png" alt="智慧用电" width="40">
					</div>
					<div>营业性场所及开放式社区智慧充电</div>
				</div>
				<div class="scenarios-item">
					<div style="background-color: #9BBB59;">
						<img src="../assets/img/d3.png" alt="智慧用电" width="40">
					</div>
					<div>校园、园区及办公楼宇智慧用电</div>
				</div>
				<div class="scenarios-item">
					<div style="background-color: #4F81BD;">
						<img src="../assets/img/d4.png" alt="智慧用电" width="40">
					</div>
					<div>智慧消防与用电安全服务</div>
				</div>
				<div class="scenarios-item">
					<div style="background-color: #1F497D;">
						<img src="../assets/img/d5.png" alt="智慧用电" width="40">
					</div>
					<div>充电设备安全管理服务</div>
				</div>
				<div class="scenarios-item">
					<div style="background-color: #8064A2;">
						<img src="../assets/img/d6.png" alt="智慧用电" width="40">
					</div>
					<div>用电侧能耗数据联网分析</div>
				</div>
				<div class="scenarios-item">
					<div style="background-color: #D98934;">
						<img src="../assets/img/d1.png" alt="智慧用电" width="40">
					</div>
					<div>双碳-节能管理系统服务</div>
				</div>

			</div>
		</div>


		<!-- 核心优势 -->
		<div class="core-main">
			<h1>核心优势</h1>
			<h4>CORE STRENGTHS</h4>
			<div class="flex flex-hw flex-sb core-box">
				<div class="core-item">
					<h2>复合检测</h2>
					<p>实时监测末端电网接入设备的用电特征，并实时建模</p>
					<div class="theletter">A</div>
				</div>
				<div class="core-item">
					<h2>精度计算</h2>
					<p>在业界率先集成高精度计量，每秒用电信号采样达到6400次</p>
					<div class="theletter">B</div>
				</div>
				<div class="core-item">
					<h2>AI大数据算法</h2>
					<p style="text-align: center;">准确率达95%以上</p>
					<div class="theletter">C</div>
				</div>
				<div class="core-item">
					<h2>首创方案</h2>
					<p>国内首创从根本上解决电动自行车室内充电“隐蔽性强、识别难、取证难”</p>
					<div class="theletter">D</div>
				</div>
				<div class="core-item">
					<h2>智能管控</h2>
					<p>全闭环处理，及时发现、预警，实现网络化、可视化、数据化管理</p>
					<div class="theletter">E</div>
				</div>
				<div class="core-item">
					<h2>专家论证</h2>
					<p style="text-align: center;">本方案及技术获得城市安全专家论证</p>
					<div class="theletter">F</div>
				</div>
				<div class="core-item">
					<h2>自主研发</h2>
					<p style="text-align: center;">持有多项原创专利</p>
					<div class="theletter">G</div>
				</div>
				<div class="core-item">
					<h2>广泛应用</h2>
					<p>智慧城市、智慧社区、智慧校园等用电安全管理场景</p>
					<div class="theletter">H</div>
				</div>
				<div class="core-item">
					<h2>支持远程升级</h2>
					<p style="text-align: center;">支持远程升级</p>
					<div class="theletter">I</div>
				</div>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import 'swiper/dist/js/swiper'
	import 'swiper/dist/css/swiper.css'
	import Swiper from "swiper"

	export default {

		metaInfo: {
			title: '微品能源',
			meta: [{
					name: 'keywords',
					content: '微品能源,智慧用电'
				},
				{
					name: 'description',
					content: '微品能源官方网站'
				}
			]
		},
		mounted() {
			 
			new Swiper('.swiper-container', {
				//direction: 'vertical', // 垂直切换选项
				//mousewheel: true, //滚轮
				autoplay: { //自动开始
					delay: 2500, //时间间隔
					disableOnInteraction: false, //*手动操作轮播图后不会暂停*
				},
				loop: true, // 循环模式选项

				// 如果需要分页器
				pagination: {
					el: '.swiper-pagination',
					clickable: true, // 分页器可以点击
				},

				// 如果需要前进后退按钮
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},

				// 如果需要滚动条
				scrollbar: {
					el: '.swiper-scrollbar',
				},
			})
		},

		methods: {
			aa() {
				this.$router.push('/about')
			}
		}

	}
</script>

<style scoped>
	.bg {
		background-size: cover;
		width: 100%;
		height: 500px;
		position: relative;
	}



	.bg .text {
		font-size: 50px;
		color: #ffffff;
		font-weight: bold;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		right: 10%;


	}

	.bg .text h4 {
		padding: 0 50px;
	}

	.bg .text .mask {
		background: rgba(0, 0, 0, 0.2);
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	.bg1 {
		background-image: url('../assets/img/1.jpg');
	}

	.bg2 {
		background-image: url('../assets/img/2.jpg');
	}

	.bg3 {
		background-image: url('../assets/img/3.jpg');
	}

	/* 核心优势 */
	.core-main {
		width: 1200px;
		margin: 40px auto;

	}

	.core-main .core-box {
		width: 1200px;

	}

	.core-box .core-item {
		border: 1px solid #e4e4e4;
		border-radius: 6px;
		padding: 20px;
		width: 300px;
		margin-bottom: 40px;
		position: relative;


	}

	.core-box .core-item h2 {}

	.core-item .theletter {
		font-size: 180px;
		font-weight: bold;
		position: absolute;
		top: 50%;
		left: 5%;
		opacity: 0.1;
		color: #1989fb;
		transform: translate(0, -50%);
		z-index: -1;
	}

	.core-box .core-item p {
		text-align: justify;
		line-height: 30px;
	}

	.scenarios-main {
		position: relative;
		padding: 50px;
	}

	.scenarios-item {
		position: absolute;
		top: 0;
		left: 200px;
		color: #727271;
	}

	.scenarios-item div:first-child {
		border-radius: 50%;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		margin: 0 auto 15px auto;
	}

	.scenarios-item img {
		vertical-align: middle;
	}

	.scenarios-item:nth-child(2) {
		top: 200px;
		left: 60px;
	}

	.scenarios-item:nth-child(3) {
		top: 450px;
	}

	.scenarios-item:nth-child(4) {
		top: 0;
		left: 800px;
	}

	.scenarios-item:nth-child(5) {
		top: 200px;
		left: 900px;
	}

	.scenarios-item:nth-child(6) {
		top: 450px;
		left: 800px;
	}
</style>
